import {useMutation, useQuery} from "react-query";
import axiosInstance from "./axios";

export async function getVideoClusters () {
  const response = await axiosInstance.get(`/screentwo/clusters/`);
  return response.data;
}

export async function createVideoCluster(data) {
  const response = await axiosInstance.post(`/screentwo/clusters/`, data);
  return response.data;
}


export async function getClusterVideos() {
 const response = await axiosInstance.get(`/screentwo/cluster-videos/`);
  return response.data;
}

export async function createClusterVideo(data) {
  const response = await axiosInstance.post(`/screentwo/cluster-videos/`, data);
  return response.data;
}


export function useVideoClusters () {
  return useQuery({
    queryKey: ['video-clusters'],
    queryFn: getVideoClusters,
  })
}

export function useVideoClustersCreateMutation () {
  return useMutation({
    mutationKey: ['video-clusters-create'],
    mutationFn: createVideoCluster,
  })
}

export function useClusterVideos () {
  return useQuery({
    queryKey: ['cluster-videos'],
    queryFn: getClusterVideos,
  })
}

export function useClusterVideoCreateMutation () {
  return useMutation({
    mutationKey: ['create-cluster-video'],
    mutationFn: createClusterVideo,
  })
}


