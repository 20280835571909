import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form, Field } from "react-final-form";
import { useClientVideos } from "../../hooks/video";
import { useNotification, useConfirmation } from "../../hooks";
import { createVideo, deleteVideo, updateVideo } from "../../states/video";
import TextInput from "../../components/fields/TextInput";
import DropzoneField from "../../components/fields/DropzoneField";
import StoreTableList from "../../components/store/StoreTableList";
import OutlineButton from "../../components/button/OutlineButton";
import StatusPill from "../../components/status/StatusPill";
import StorePicker from "../../components/store/StorePicker";


const ClientConfigVideoContainer = () => {
  const params = useParams();
  const dispatch = useDispatch();
  // const client = useClient(params.clientId)
  const videoRef = useRef();
  const clientVideos = useClientVideos(params.clientId);
  const { showNotificationSuccess, showNotificationError } = useNotification();
  const { confirmAction } = useConfirmation();
  const [currentVideo, setCurrentVideo] = useState();
  const [editMode, setEditMode] = useState(false);
  const [currentVideoTitle, setCurrentVideoTitle] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentVideoStores, setCurrentVideoStores] = useState(
    currentVideo?.stores
  );
  const [showStorePicker, setShowStorePicker] = useState(false);
  const [titleUpdateLoading, setTitleUpdateLoading] = useState(false);
  const [storeUpdateLoading, setStoreUpdateLoading] = useState(false);

  useEffect(() => {
    setCurrentVideoStores(currentVideo?.stores);
    videoRef.current.load();
  }, [currentVideo]);

  const handleVideoDelete = async (videoId) => {
    console.log("Deleting video: ", videoId);
    const response = await confirmAction(
      `Are you sure you want to delete video ?`
    );
    if (response) {
      try {
        await dispatch(deleteVideo(videoId)).unwrap();
        showNotificationSuccess("Success", "Delete successfull!");
      } catch (videoDeleteError) {
        showNotificationError("Error", "Video failed to delete");
      }
    }
  };

  const handleVideoUpdate = async () => {
    try {
      setTitleUpdateLoading(true);
      await dispatch(
        updateVideo({ title: currentVideoTitle, id: currentVideo.id })
      ).unwrap();
      showNotificationSuccess("Success", "Update successfull!");
    } catch (videoDeleteError) {
      showNotificationError("Error", "Video failed to update");
    } finally {
      setTitleUpdateLoading(false);
    }
  };

  const handleVideoCreate = async (values) => {
    const { title, video } = values;
    try {
      const newFormData = new FormData();
      newFormData.append("video", video[0]);
      newFormData.append("title", title);
      newFormData.append("owner", params.clientId);
      await dispatch(
        createVideo({ formData: newFormData, onUploadProgress })
      ).unwrap();
      showNotificationSuccess("Success", "Update successfull!");
      // clear the field , how ?
    } catch (videoDeleteError) {
      showNotificationError("Error", "Video failed to update");
    } finally {
      setUploadProgress(0);
    }
  };

  const handleVideoStoreUpdate = async (storesIds) => {
    console.log("updating videos to : ", storesIds);
    try {
      const response = await dispatch(
        updateVideo({
          stores: [...storesIds],
          id: currentVideo.id,
        })
      ).unwrap();
      showNotificationSuccess("Success", "Update successfull!");
      setCurrentVideoStores(response.stores);
    } catch (videoDeleteError) {
      showNotificationError("Error", "Video failed to update");
    }
  };

  const handleStorePickerSubmit = async (stores) => {
    setShowStorePicker(false);
    setStoreUpdateLoading(true);
    await handleVideoStoreUpdate([
      ...currentVideo.stores,
      ...stores.map((s) => s.id),
    ]); // the previous stores and the new ones
    setStoreUpdateLoading(false);
  };

  const removeStoreFromVideo = async (store) => {
    const { id: storeId } = store;
    console.log("removing storeId: ", storeId);
    const response = await confirmAction(
      `Are you sure you want to delete store on " ${currentVideo.title} " ?`
    );
    if (response) {
      setStoreUpdateLoading(true);
      const filteredStore = currentVideoStores.filter((sId) => sId !== storeId);
      await handleVideoStoreUpdate(filteredStore);
      setStoreUpdateLoading(false);
    }
  };

  const onVideoItemClick = (video) => {
    setCurrentVideo(video);
    setCurrentVideoTitle(video.title);
    setEditMode(false);
  };

  const onUploadProgress = (e) => {
    setUploadProgress(Math.round((100 * e.loaded) / e.total));
  };

  const handleStorePickerCancel = () => {
    setShowStorePicker(false);
  };

  return (
    <div className="client-video-container">
      <div className="video-config">
        {showStorePicker && (
          <StorePicker
            handleCancel={handleStorePickerCancel}
            handleSubmit={handleStorePickerSubmit}
            excludes={currentVideoStores}
          />
        )}
        <div className="video-config__creator">
          <div className="creator__header">NEW VIDEO</div>
          <Form onSubmit={handleVideoCreate}>
            {({ handleSubmit, values, reset, submitting, pristine }) => (
              <form
                onSubmit={(event) => handleSubmit(event).then(() => reset?.())}
              >
                <div className="creator__content">
                  <div className="creator__left">
                    <Field name="video">
                      {({ input }) => (
                        <DropzoneField
                          input={input}
                          className="upload-box"
                          accept="video/*"
                        />
                      )}
                    </Field>
                  </div>
                  <div className="creator__right">
                    <Field name="title">
                      {({ input }) => (
                        <TextInput
                          {...input}
                          type="text"
                          placeholder="Enter Video Title ..."
                        />
                      )}
                    </Field>
                    <OutlineButton loading={submitting} disabled={pristine}>
                      Add Video
                    </OutlineButton>
                    <pre></pre>
                  </div>
                </div>
              </form>
            )}
          </Form>

          {uploadProgress > 0 && (
            <progress value={uploadProgress} max="100">
              {uploadProgress}
            </progress>
          )}
        </div>

        <div className="video-config__header">
          <div className="video-title__wrapper">
            <span className="video-title__label">video ad</span>

            {!editMode ? (
              <>
                <div className="video-title__input">
                  {currentVideo?.title ?? "N/A - select a video below"}
                </div>
                <span
                  className="video-title__icon"
                  onClick={(e) => setEditMode(true)}
                >
                  <ion-icon name="create-outline"></ion-icon>
                </span>
              </>
            ) : (
              <input
                className="video-title__input"
                value={currentVideoTitle}
                onChange={(e) => setCurrentVideoTitle(e.target.value)}
                style={{
                  color: "#ccc",
                  backgroundColor: "transparent",
                  marginRight: "0.5rem",
                }}
                disabled={titleUpdateLoading}
              />
            )}
            {editMode && (
              <OutlineButton
                onClick={handleVideoUpdate}
                loading={titleUpdateLoading}
              >
                update
              </OutlineButton>
            )}
          </div>
        </div>

        <div className="video-config__player">
          <video controls style={{ height: "100%" }} ref={videoRef}>
            <source src={currentVideo?.video} type="video/mp4" />
          </video>
        </div>

        <div className="video-config__videos">
          <div className="videos__header">
            <div className="videos__label">
              <span>video ads</span>
              <StatusPill label="active" success />
            </div>
          </div>
          <div className="videos__content">
            <div className="videos__content-header">
              {[1, 2].map((n) => (
                <div key={n} className="videos__content-header-item">
                  <li>video file</li>
                  <li></li>
                  <li>Delete</li>
                </div>
              ))}
            </div>
            <div className="videos__content-items">
              {clientVideos.map((video) => (
                <div key={video.id} className="videos__content-item">
                  <div
                    className="video-item"
                    onClick={(e) => onVideoItemClick(video)}
                  >
                    <span>
                      <ion-icon name="videocam-outline"></ion-icon>
                    </span>
                    <div className="video-item__title">{video.title}</div>
                    {/* <div className="video-item__info">
                        <ion-icon name="information-circle-outline"></ion-icon>
                      </div> */}
                  </div>
                  <span
                    className="video-delete"
                    onClick={(e) => handleVideoDelete(video.id)}
                  >
                    <ion-icon name="trash"></ion-icon>
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="video-config__locations">
          <div className="locations__header">
            <div className="locations__label">
              <span>locations</span>
              <StatusPill
                label="active"
                success={currentVideoStores?.length > 0}
              />
            </div>
            <div
              className="locations__input"
              onClick={(e) => setShowStorePicker(true)}
            >
              Add Locations
              <span>
                <ion-icon name="add-outline"></ion-icon>
              </span>
            </div>
          </div>

          <div className="locations__content">
            <StoreTableList
              storesIds={currentVideoStores}
              loading={storeUpdateLoading}
              onItemDelete={removeStoreFromVideo}
              onItemClick={(e) => {}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientConfigVideoContainer;
