import SidebarItem from "./components/SidebarItem";
import { useUser } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";

const SidebarUser = ({ username, onLogoutClick }) => {
  return (
    <div className='sidebar__user'>
      <span>
        <ion-icon name='person-outline'></ion-icon>
      </span>
      <span>{username ?? "N/A"}</span>
      <span onClick={onLogoutClick}>
        <ion-icon name='power-outline'></ion-icon>
      </span>
    </div>
  );
};

const Sidebar = () => {
  const user = useUser();
  const navigate = useNavigate();

  const logoutUser = () => {
    console.log("logging out ... ");
    // clear out the local storage token
    // clear out/ invalidate the session token on the server
    navigate("/login");
  };

  return (
    <div className='sidebar'>
      <div className='sidebar__header'>
        <img src='/logo.png' style={{ width: "100%" }} alt='logo' />
      </div>
      <ul className='sidebar__items'>
        {user?.is_clientuser && (
          <SidebarItem title='Reports' icon='bar-chart-outline' to='/' />
        )}
        {(user?.is_superuser || user?.is_adminuser) && (
          <>
            <SidebarItem title='Dashboard' icon='grid-outline' />
            <SidebarItem title='Clients' icon='briefcase-outline' to='/clients' />
            <SidebarItem title='Vabes' icon='desktop-outline' to='/devices' />
            <SidebarItem title='Stores' icon='cart-outline' to='/stores' />
            <SidebarItem title='Reports' icon='bar-chart-outline' to='/reports' />
            <SidebarItem title='AI Stat' icon='bar-chart-outline' to='/stat' /> 
            <SidebarItem title='Maps' icon='navigate-outline' to='/map' />
            <SidebarItem title='Sscreen' icon='albums-outline' to='/sscreen' />
          </>
        )}
        {user?.is_superuser && <SidebarItem title='Users' icon='people-outline' to='/users' />}
      </ul>
      <SidebarUser username={user?.username} onLogoutClick={logoutUser} />
    </div>
  );
};

export default Sidebar;
