import React, {useState} from 'react';
import {useParams} from 'react-router-dom'
import {Form, Field} from 'react-final-form';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import OutlineButton from '../../components/button/OutlineButton';
import DropzoneField from '../../components/fields/DropzoneField';
import TextInput from '../../components/fields/TextInput';
import StoreTableList from '../../components/store/StoreTableList'
import StorePicker from '../../components/store/StorePicker';
import {
  createWheel, 
  createWheelPie, 
  deleteWheel, 
  deleteWheelPie, 
  updateWheel, 
  updateWheelPie
} from '../../states/stw';
import {setStoresSTW} from '../../states/store';
import { useConfirmation, useNotification } from '../../hooks';
import {useClientWheels, useSTWWheel} from '../../hooks/stw';

const SliceItem = ({slice, onItemDelete, onItemClick}) => {
  return (
    <div className="slice-item" >
      <div className="slice-item__content" onClick={e => onItemClick(slice)}>
        <span> <ion-icon name="pie-chart-outline"></ion-icon> </span>
        <span>{slice.label} | Odd {slice.probability}%</span>
      </div>
      <div className="slice-item__delete" onClick={e => onItemDelete(slice)}>
        <ion-icon name="trash-outline"></ion-icon>
      </div>
    </div>
  )
}

const WheelTableList = ({wheels, onItemClick, currentWheel, onItemDelete}) => {
  return (
    <div className="wheel-table-list">
      <div className="wheel-table-list__content" >
      {wheels?.map ( wheel => (
        <div 
          className={classNames('wheel-item', {'active': currentWheel?.id === wheel.id})} 
          onClick={e => onItemClick(wheel)}
          key={wheel.id}
        >
          <span className='wheel-item__title'> 
            <ion-icon name="game-controller-outline"></ion-icon>
            {wheel.name} 
          </span>
          <span className='wheel-item__icon' onClick={e => onItemDelete (wheel)}>
            <ion-icon name="trash-outline"></ion-icon>
          </span>
        </div>
      ))}
      </div>
    </div>
  )
}

const ClientConfigGameContainer = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const clientWheels = useClientWheels(params.clientId)
  const {showNotificationSuccess, showNotificationError } = useNotification()
  const {confirmAction} = useConfirmation()
  const [currentWheel, setCurrentWheel] = useState()
  const [currentSlice, setCurrentSlice] = useState()
  const [wheelEditMode, setWheelEditMode] = useState(false)
  const [pieEditMode, setPieEditMode] = useState(false)
  const currentWheelLiveData = useSTWWheel(currentWheel?.id)
  const [showStorePicker, setShowStorePicker] = useState(false)

  console.log('client wheels : ', clientWheels)
  
  const handleSTWCreate = async (values) => {
    console.log('creating stw ...', values)
    const {name, max_prize, max_wins_day, logo} = values
    const formData = new FormData()

    formData.append('name', name)
    formData.append('max_prize', max_prize)
    formData.append('max_wins_day', max_wins_day)

    if (Array.isArray(logo)) formData.append('logo', logo[0])
    formData.append('owner', params.clientId)

    try {
      await dispatch(createWheel(formData)).unwrap()
      showNotificationSuccess('Created Successfully', '')
    } catch (createError) {
      console.error(createError)
      showNotificationError('Error', '')
    }
  }
  
  const handleSTWUpdate = async ( values ) => {
    try {
      const response = await dispatch(updateWheel({...values})).unwrap()
      showNotificationSuccess('Update Successful', '')
      return response
    } catch (createError) {
      console.error(createError)
      showNotificationError('Error', '') 
    }
  }
  
  const handleSTWUpdateTitleImage = async (values) => {
    const {name, max_prize, max_wins_day, logo, color_1, color_2} = values
    const formData = new FormData()
    if (Array.isArray(logo)) formData.append('logo', logo[0]);
    if(name?.length > 0) formData.append('name', name);
    formData.append('color_1', color_1);
    formData.append('color_2', color_2);

    formData.append('max_prize', max_prize)
    formData.append('max_wins_day', max_wins_day)

    await handleSTWUpdate({wheelId: currentWheel.id, formData})
  }

  const handlePieCreate = async (values) => {
    console.log('pie create data: ', values)
    const {label, image, probability, win } = values
    const formData = new FormData()
    formData.append('label', label)
    if (Array.isArray(image)) formData.append('image', image[0])
    formData.append('probability', probability)
    formData.append('win', JSON.parse(win))
    formData.append('stw', currentWheel.id)
    try {
      await dispatch(createWheelPie(formData)).unwrap()
      showNotificationSuccess('Created Successfully', '')
    } catch (createError) {
      console.error(createError)
      showNotificationError('Error', '') 
    }
  }

  const handlePieUpdate = async (values) => {
    const {label, image, probability, win } = values
    const formData = new FormData()
    formData.append('label', label)
    if (Array.isArray(image)) formData.append('image', image[0])
    formData.append('probability', probability)
    formData.append('win', JSON.parse(win))
    formData.append('stw', currentWheel.id)
    try {
      await dispatch(updateWheelPie({pieId: currentSlice?.id , formData})).unwrap()
      showNotificationSuccess('Update Success', '')
      handleEditCancel()
    } catch (upateError) {
      console.error(upateError)
      showNotificationError('Update Error', '') 
    }
  }

  const handleStorePickerCancel = () => {
    setShowStorePicker(false)
  }

  const handleStorePickerSubmit = async ( stores ) => {
    const formData = new FormData()
    for(let store of stores )
      formData.append('stores', store.id) // add the newly selected store 
  
      for( let storeId of currentWheelLiveData.stores) // don't forget the old stores 
      formData.append('stores', storeId) // add the newly selected store 

    const response = await handleSTWUpdate({wheelId: currentWheel.id, formData})
    setShowStorePicker(false)
    if (response) {
      console.log('dispatch setStoreSTW: ', stores, currentWheelLiveData.id)
      dispatch(setStoresSTW({
        stwId: currentWheelLiveData.id , 
        stores: [...stores.map(s => s.id)]
      }))
    }
  }

  const handleWheelStoreDelete = async (store) => {    
    const {id: storeId, name} = store
    const response = await confirmAction(`Are you sure you want to remove game from store: (${name})?`)
    if (response) {
      const filteredStores = currentWheelLiveData.stores.filter( s => s !== storeId)
      console.log('update store : ', filteredStores)

      const formData = new FormData()
      for(let storeId of filteredStores)
        formData.append('stores', storeId) // add the newly selected store 

      await handleSTWUpdate({wheelId: currentWheel.id, formData})
    }
  }

  const handleWheelDelete = async (wheel) => {
    const response = await confirmAction(`Are you sure you want to delete wheel ?`)
    if ( response ) {
      try {
        await dispatch(deleteWheel({wheelId: wheel.id})).unwrap()
        showNotificationSuccess('Delete Success', '')
      } catch ( deleteError ) {
        console.error(deleteError)
        showNotificationError('Delete Error', '')
      }
    }
  }

  const handlePieDelete = async (pie) => {
    console.log('Deleting pie ... ', pie) 
    const response = await confirmAction(`Are you sure you want to delete Pie ?`)
    if ( response ) {
      try {
        await dispatch(deleteWheelPie({stwId: currentWheel.id, pieId: pie.id})).unwrap()
        showNotificationSuccess('Delete Success', '')
      } catch ( deleteError ) {
        console.error(deleteError)
        showNotificationError('Delete Error', '')
      }
    }
  }

  const handleWheelClick = (wheel) => {
    handleEditCancel() // cancel any form of editing
    handleWheelEditCancel()
    setCurrentWheel(wheel)
    setWheelEditMode(true)
  }

  const handleSliceClick = (slice) => {
    setCurrentSlice(slice)
    setPieEditMode(true) //
  }

  const handleEditCancel = () => {
    setPieEditMode(false)
    setCurrentSlice() // debug
  }

  const handleWheelEditCancel = () => {
    setWheelEditMode(false)
    setCurrentWheel() // debug
  }

  return (
    <div className='client-game-container'>
      <div className="game-config">
      { showStorePicker && 
          <StorePicker 
            handleCancel={handleStorePickerCancel} 
            handleSubmit={handleStorePickerSubmit} 
            excludes={currentWheelLiveData?.stores}
          />
        }
        <div className="game-config__header">spin the wheel</div>
        
        <div className="game-config__work-section">
          
          <Form 
            onSubmit={wheelEditMode ? handleSTWUpdateTitleImage : handleSTWCreate} 
            initialValues={currentWheel ?? {}}
          >
            {( {handleSubmit, submitting, values}) => (       
            <div className="game-section">
              <div className="form__group">
                <label htmlFor="">Game Name</label>
                <Field name="name"> 
                {({input}) => <TextInput {...input} placeholder='Title Label'  type="text" />}
                </Field>
              </div>
              
              <div className="form__group">
                <p style={{marginBottom: '10px'}}>Client Colors</p>
                <Field name='color_1'>{({input}) => <input  style={{marginRight: '5px'}} type="color" {...input}/> }</Field>
                <Field name='color_2'>{({input}) => <input type="color" {...input}/> }</Field>
              </div>

              <div className="form__group">
                <label htmlFor="">Max Prize</label>
                <Field name='max_prize'>{({input}) => <input type="number" {...input}/> }</Field>
              </div>

              <div className="form__group">
                <label htmlFor="">Prize per Day</label>
                <Field name='max_wins_day'>{({input}) => <input type="number" {...input}/> }</Field>
              </div>

              <div className="form__group">
                <label htmlFor="">Insert Logo</label>
                <Field name="logo">
                  { ({input}) => (
                     <DropzoneField 
                      input={input} 
                      className="game-logo__dropzone" 
                      image={wheelEditMode && currentWheel?.logo}
                    />
                  )}
                </Field>
              </div>
              <div className="form__group"  style={{display: 'flex', gap: '1rem'}}>
                <OutlineButton onClick={handleSubmit} loading={submitting}>
                  { wheelEditMode ?  'Update Game' :  'Create Game' }
                </OutlineButton>
                {wheelEditMode && <OutlineButton onClick={handleWheelEditCancel}>Cancel Edit</OutlineButton> }
              </div>
                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
            </div>
            )}
          </Form>

          <Form 
            onSubmit={pieEditMode ? handlePieUpdate :  handlePieCreate} 
            initialValues={currentSlice ? {...currentSlice, win: JSON.stringify(currentSlice.win)} : {}}
          > 
            {({handleSubmit, submitting, values, form}) => (
            <div className="slice-section">
                
              <div className="form__group">
                <label htmlFor="">Game Slice</label>
                <Field name="label">
                { ({input}) => <TextInput {...input} placeholder='Label'  type="text" /> }
                </Field>
              </div>
              <div className="odds-n-win">
                <div className="form__group">
                  <label htmlFor="">ODDS</label>
                  <Field name="probability">
                  { ({input}) => <TextInput {...input} placeholder='percentage' /> }
                  </Field>
                </div>
                <div className="form__group">
                  <label htmlFor="">PRIZE WINNER</label>
                  <div className="win">
                    <Field name="win" type="radio" value={"true"}>
                    { ({input}) => (<label htmlFor="r1"><input type="radio" id='r1' {...input} /> True</label> )}
                    </Field>                    
                    <Field name="win" type="radio" value={"false"}>
                    { ({input}) => (<label htmlFor="r2"><input type="radio" id='r2' {...input} /> False</label> )}
                    </Field>                    
                  </div>
                </div>
              </div>
              <div className="form__group">
                <label htmlFor="">Slice Image</label>
                <Field name="image">
                { ({input}) => ( 
                  <DropzoneField 
                    className="slice-logo__dropzone" 
                    input={input} 
                    image={pieEditMode && currentSlice?.image}
                  /> )}
                </Field>
              </div>
              <p style={{margin: '0.5rem 0'}}>Selected Wheel: {currentWheel?.name}</p>
              <div className="form__group" style={{display: 'flex', gap: '1rem'}}>
                <OutlineButton 
                  onClick={handleSubmit} 
                  disabled={!currentWheel} 
                  loading={submitting}
                >
                  { pieEditMode ?  'Update Slice' :  'Create Slice' }
                </OutlineButton>
                {pieEditMode && <OutlineButton onClick={handleEditCancel}>Cancel Edit</OutlineButton> }
              </div>
            </div>
            )} 
          </Form>


        </div>

        <div className="game-config__list-section">
          <div className="header">
            Wheels
          </div>
          <WheelTableList 
            wheels={clientWheels} 
            onItemClick={handleWheelClick} 
            onItemDelete={handleWheelDelete}
            currentWheel={currentWheel}
          />
        </div>
        
        <div className="game-config__slice-section">
          <div className="slices__header">game slices</div>
          <div className="slices__content">
            {currentWheelLiveData?.pies?.map( slice => (
              <SliceItem 
                key={slice.id} 
                slice={slice} 
                onItemDelete={handlePieDelete}
                onItemClick={slice => handleSliceClick(slice)}
              /> ) 
            ) ?? <p>There's no pie slice to show !</p> }
          </div>
        </div>

        <div className="game-config__locations-section">
          <div className="locations__header">
            <div className="locations__header-title">
              Locations
            </div>
            <div className="locations__header-button" onClick={e => setShowStorePicker(true)}>
              Add locations
              <ion-icon name="add-outline"></ion-icon>
            </div>
          </div>
          <div className="locations__content">
            <StoreTableList 
              storesIds={currentWheelLiveData?.stores} 
              onItemClick={null} 
              onItemDelete={handleWheelStoreDelete} 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientConfigGameContainer;
