import { useState, useEffect } from "react";
import { useClusterVideoCreateMutation, useClusterVideos, useVideoClusters, useVideoClustersCreateMutation } from "../utils/clusters";


function SecondaryScreenManagementPage () {
  const {data: clusters, isLoading} = useVideoClusters();
  const {data: videos, isVideoLoading} = useClusterVideos();

  const [selectedCluster, setSelectedCluster] = useState();
  const [showClusterCreate, setShowClusterCreate] = useState();
  const [clusterTitle, setClusterTitle] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [disableClusterCreate, setDisableClusterCreate] = useState(false);
  const [disableVideoCreate, setDisableVideoCreate] = useState(false);

  const createMutation = useVideoClustersCreateMutation();
  const videoCreateMutation = useClusterVideoCreateMutation();

  useEffect(() => {
    console.log('this fired');
    if(!isLoading && clusters && clusters.length > 0)
      setSelectedCluster(clusters[0]);
  }, [isLoading]);


  const _createVideo = () => {
    videoCreateMutation.mutate({
      title: videoTitle, clusters: [selectedCluster.id]}, {
      onSuccess: () => { 
        window.alert('success');
        setVideoTitle('');
      },
      onError: () => window.alert('failed'),
      onSettled: () => setDisableVideoCreate(false),
      onMutate: () => setDisableVideoCreate(true),
    })
  }

  const _createVideoCluster = () => {
    createMutation.mutate({title: clusterTitle}, {
      onSuccess: () => { 
        window.alert('success');
        setClusterTitle('');
        setShowClusterCreate(false);
      },
      onError: () => window.alert('failed'),
      onSettled: () => setDisableClusterCreate(false),
      onMutate: () => setDisableClusterCreate(true),
    })
  }

  const _deleteSelectedCluster = () => {}

  if (isLoading) return <p>Loading ... </p>


  console.log('selected cluster: *** ', selectedCluster);
  
  return (
    <div className="secondary-screen">
      <div className="cluster-component">

        <div className="cluster-selector-section">
          <div className="header">
            <span>Video clusters</span>
            <div className="actions">
              <button disabled onClick={_deleteSelectedCluster} className="delete">Delete selected cluster</button>
              <button onClick={_ => setShowClusterCreate(p => !p)}>Create video cluster</button>
            </div>
          </div>
          <div className="cluster-items">
            {clusters.map(cluster => 
              <span 
                key={cluster.id}
                className={`cluster-item ${selectedCluster?.id === cluster.id ? 'selected': ''}`}
                onClick={_ => setSelectedCluster(cluster)}
              >
                {cluster.title}
              </span> 
            )}
          </div>
        </div>


        <div 
          className="cluster-create-section" 
          style={{height: `${showClusterCreate ? '' : '0px'}`, display: `${showClusterCreate ? '' : 'none'}`}}
        >
          <div className="header">
            <p>Create cluster</p>
            <button onClick={_ => setShowClusterCreate(false)}>X</button>
          </div>
          <div className="cluster-create-form">
            <div className="cluster-title">
              <label>cluster title</label>
              <input  placeholder="Enter cluster title" value={clusterTitle} onChange={e => setClusterTitle(e.target.value)} />
            </div>
            <button onClick={_createVideoCluster} disabled={disableClusterCreate || clusterTitle.length === 0}>
              Create cluster
            </button>
          </div>
        </div>

        <div className="cluster-videos-section">
          <div className="headers">
            <span>Associated videos</span>
            <button disabled className="delete">Delete selected video</button>
          </div>
          <div className="cluster-video-items">
            {!isVideoLoading && videos?.filter(v => v.clusters.includes(selectedCluster?.id))
              .map(video => 
              <div className="cluster-video-item">
                <ion-icon name="play-circle-outline"></ion-icon>
                <span>{video.title}</span>
              </div> 
            )}
          </div>
        </div>


        <div className="cluster-videos-create-section">
          <p>Create cluster video</p>
          <div className="video-create-form">
            <div className="video-title">
              <label>video title</label>
              <input className="video-title" placeholder="Enter video title"  
                value={videoTitle} onChange={e => setVideoTitle(e.target.value)}
              />
            </div>

            <div className="video-file">
              <label>video title</label>
              <input type="file" />
            </div>

            <p className="video-note">
              <ion-icon name="information-circle-outline"></ion-icon>
              <span>Note that the created video will be associated with the currently selected cluster</span>
            </p>
            <button onClick={_createVideo} disabled={disableVideoCreate || videoTitle.length === 0}>Create video</button>
          </div>
        </div>

      </div>
    </div>
  )
}

export default SecondaryScreenManagementPage;
